body {
    //.page-header{
    .bg-video{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        overflow: hidden;

        .bg-video__content{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .page-header{
        .center-content{
            display: flex;
            flex-direction: column;
            align-items: center;

            .avatar{
                position: relative;
                width: 20rem;
                height: 20rem;
                overflow: hidden;
                border-radius: 50%;

                img{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    -webkit-transform: scale(2) translateY(1rem);
                    -ms-transform: scale(2) translateY(1rem);
                    transform: scale(2) translateY(1rem);
                }
            }

            .header-designation{
                font-size: 2rem;
                text-align: center;
                margin-top: 2rem;
            }
        }
    }

    .content-section{
        margin-top: 2rem;

        &.first{
            margin-top: -60px;
        }

        .skillset-wrapper{
            p {
                font-size: 1rem;
            }
        }
    }

    .skillmeter{
        .skill-info{
            display: flex;
            align-items: center;
            justify-content: space-between;

            .skilllogos{
                display: flex;
                .logo-item{
                    width: 1.5rem;
                    height: 1.5rem;
                    overflow: hidden;
                    margin: 0 0 0.3rem 0.3rem;
                    .logo-button{
                        margin: 0;
                        padding: 0;
                    }

                    img{
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    .header-bar{
        z-index: 4;
    }
    .fullscreen{
        .react-images__blanket{
            z-index: 5;
        }

        .react-images__positioner{
            z-index: 6;
        }
    }

    .footer-info{
        color: white;
        a {
            color: wheat;
        }
    }
}

